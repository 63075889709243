@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Merriweather', 'Work Sans'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'DM Mono',
    monospace;
}

/* @layer base {
  @font-face {
    font-family: "8-bit";
    src: url("public/fonts/8-bit pusab.ttf");
  }
} */

@font-face {
  font-family: 'Pusab';
  src: url('./fonts/8-bit\ pusab.ttf');
}

@font-face {
  font-family: 'Wimp Out';
  src: url('./fonts/wimp.ttf');
}
