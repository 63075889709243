.EZDrawer .EZDrawer__container {
    position: fixed;
    visibility: hidden;
    transition: all;
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.css-mot4fi-MuiPaper-root-MuiAppBar-root {
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
    z-index: 2 !important;
}

.EZDrawer__container {
    z-index: 1 !important;
}

.EZDrawer__overlay {
    z-index: 0 !important;
}

.carousel .slide iframe {
    width: 100% !important;
    margin: 0 0px 0px !important;
}

/* .css-19752vc-MuiPaper-root-MuiAppBar-root {
    z-index: 100 !important;
} */